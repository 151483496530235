<template>
  <div class="card">
    <div class="card-content">
      <div class="media is-align-items-center mb-6">
        <div class="media-content">
          <p class="paragraph has-text-weight-bold text_primary_green">
            {{ title }}
          </p>
        </div>
        <div class="media-right">
          <button class="button button_micro_dark_outlined">
            <span class="icon">
              <i class="material-icons">
                download
              </i>
            </span>
            <download-csv class="btn btn-default" :data="jsonData" :name="`${filename}.csv`">
              DESCARGAR
            </download-csv>
          </button>
        </div>
      </div>
      <div :class="[ jsonData.length === empty ? 'block' : 'content' ]">
        <template v-if="jsonData.length === empty">
          <p class="paragraph has-text-centered has-text-weight-bold">
            Sin información
          </p>
        </template>
        <template v-else>
          <div class="block" v-for="(product, index) in jsonData" :key="product.id">
            <div class="media">
              <div class="media-left">
                <p class="paragraph has-text-weight-bold text_primary_green">
                  {{ index + 1 }}
                </p>
              </div>
              <div class="media-content pr-5">
                <p class="paragraph has-text-weight-bold mb-1">
                  {{ product.name }} {{ product.catalogue ? `(${product.catalogue})` : '' }}
                  <span v-if="product.product_variable_id">
                    <br>
                    <small>Variante: {{ product.variable_name }} {{ product.variable_catalogue ? `(${product.variable_catalogue})` : '' }}</small>
                  </span>
                </p>
                <span class="paragraph">
                  {{ product.mi_key === "null" ? "Producto no tiene clave MI" : product.mi_key  }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import JsonCSV from 'vue-json-csv'

Vue.component('downloadCsv', JsonCSV)

export default {
  name: 'CardProductListMost',
  data () {
    return {
      empty: 0
    }
  },
  props: {
    title: {
      type: String,
      default: null
    },
    jsonData: {
      type: Array,
      default: () => []
    },
    filename: {
      type: String,
      default: 'downloadData'
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
  }
  .content {
    height: 500px;
    overflow: hidden;
    overflow-y: scroll;
  }
  .button {
    font-size: 15px;
  }
</style>
